































































































import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  GET_ONBOARDING_LOADING,
  SET_ONBOARDING_LOADING
} from "@/store/modules/onboarding/constants";
import { SiteDirections } from "@/store/modules/common/interfaces";
import { SITE_DIRECTION, UPLOAD_FILES } from "@/store/modules/common/constants";
import { TranslateResult } from "vue-i18n";

export default Vue.extend({
  name: "CandidateAvatar",
  data() {
    return {
      avatar: null as File | null,
      drop_file_image: require("@/assets/illustrations/file.svg"),
      selected_avatar: null as string | ArrayBuffer | null,
      error_msg: "" as TranslateResult,
      show_error_alert: false as boolean
    };
  },
  computed: {
    SiteDirections() {
      return SiteDirections;
    },
    ...mapGetters("common", {
      get_site_direction: SITE_DIRECTION
    }),
    ...mapGetters("onboarding", {
      get_onboarding_loading: GET_ONBOARDING_LOADING
    })
  },
  methods: {
    ...mapActions("common", {
      upload_files: UPLOAD_FILES
    }),
    ...mapMutations("onboarding", {
      set_onboarding_loading: SET_ONBOARDING_LOADING
    }),
    /**
     * Validate user selected avatar through drag & drop
     * @param value{DragEvent}
     */
    process_dropped_avatar(value: DragEvent) {
      if (value && value.dataTransfer) {
        this.avatar = value.dataTransfer.files[0];
        if (this.validate_avatar_type(this.avatar)) {
          this.get_avatar_url(this.avatar);
        }
      }
    },
    /**
     * Validate user selected avatar through file uploader
     * @param file{File}
     */
    process_selected_avatar(file: File) {
      this.avatar = file;
      if (this.validate_avatar_type(this.avatar)) {
        this.get_avatar_url(this.avatar);
      }
    },
    /**
     * Validate selected avatar type: .png, .jpg & .svg are valid types
     * @param file{File} => selected file
     * @return boolean
     */
    validate_avatar_type(file: File): boolean {
      if (file.size > 2e6) {
        this.error_msg = this.$t("candidate-onboarding.size-error");
        this.show_error_alert = true;
        this.avatar = null;
        this.selected_avatar = null;
        return false;
      } else if (
        file.type === "image/svg+xml" ||
        file.type === "image/png" ||
        file.type === "image/jpeg" ||
        file.type === "image/jpg"
      ) {
        this.error_msg = "";
        this.show_error_alert = false;
        return true;
      } else {
        this.error_msg = this.$t("candidate-onboarding.unsupported-error");
        this.show_error_alert = true;
        this.avatar = null;
        this.selected_avatar = null;
        return false;
      }
    },
    /**
     * Get avatar url to display
     * @param avatar{File} => selected file
     */
    get_avatar_url(avatar: File) {
      const reader = new FileReader();
      reader.addEventListener("load", (e: ProgressEvent) => {
        const target = e.target as FileReader;
        if (target) this.selected_avatar = target.result;
        else {
          this.error_msg = this.$t("candidate-onboarding.unsupported-error");
          this.avatar = null;
          this.selected_avatar = null;
        }
      });
      reader.readAsDataURL(avatar);
    },
    /**
     * Convert file size from bytes to MB
     * @param size{number} file size
     * @return string file size in MB
     */
    get_file_size(size: number): string {
      return (size / (1024 * 1024)).toFixed(2);
    },
    /**
     * Reset variables
     */
    reset_avatar() {
      this.avatar = null;
      this.selected_avatar = null;
      this.error_msg = "";
      this.show_error_alert = false;
    },
    /**
     * Submit form => pass data to parent with step and avatar file
     */
    async submit_form() {
      // Convert file to Blob
      this.set_onboarding_loading(true);
      const obj = new FormData();
      const file = this.avatar as File;
      obj.append("file", file);
      obj.append("path", `files/avatar`);
      const files = await this.upload_files(obj);
      if (files) {
        this.$emit("update_stepper", {
          step: 1,
          value: files
        });
      }
      this.set_onboarding_loading(false);
    }
  }
});


















































































import Vue from "vue";

export default Vue.extend({
  name: "NepStep4",
  data: () => ({
    valid: false,
    why_are_you_interested_in_joining_the_national_experts_program: "",
    how_many_years_have_you_worked_in_your_sector: "",
    provide_3_specific_examples_of_trends_that_you_believe_will_change_your_sector_in_the_next_five_years_and_why:
      ""
  }),
  methods: {
    submit_form() {
      if (this.valid) {
        const payload = {
          why_are_you_interested_in_joining_the_national_experts_program:
            this.why_are_you_interested_in_joining_the_national_experts_program,
          how_many_years_have_you_worked_in_your_sector:
            this.how_many_years_have_you_worked_in_your_sector,
          provide_3_specific_examples_of_trends_that_you_believe_will_change_your_sector_in_the_next_five_years_and_why:
            this
              .provide_3_specific_examples_of_trends_that_you_believe_will_change_your_sector_in_the_next_five_years_and_why
        };
        this.$emit("update_stepper", { step: 4, value: payload });
      }
    }
  }
});























































































import Vue from "vue";
import {
  GET_CANDIDATE_DATA,
  NEP_POST_DATA,
  RESET_CANDIDATE_DATA,
  SET_CANDIDATE_DATA,
  SET_ONBOARDING_LOADING
} from "@/store/modules/onboarding/constants";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  CandidateData,
  SetCandidateDataPayload
} from "@/store/modules/onboarding/interfaces";
import CandidateAvatar from "@/views/onboarding/candidate/CandidateAvatar.vue";
import CandidateResume from "@/views/onboarding/candidate/CandidateResume.vue";
import { GET_USER_DETAILS, USER_STATE } from "@/store/modules/auth/constants";
import { UserState } from "@/store/modules/auth/interfaces";
import { ROOT_ERROR } from "@/store/modules/root/constants";
import { NepPayload } from "@/content";
import NepStep3 from "@/views/nep/NepStep3.vue";
import NepStep4 from "@/views/nep/NepStep4.vue";

export default Vue.extend({
  name: "CandidateIndex",
  components: {
    CandidateResume,
    CandidateAvatar,
    NepStep3,
    NepStep4
  },
  data() {
    return {
      candidate_stepper: 1 as number,
      back_icon: require("@/assets/icons/linear/arrow-left-1.svg"),
      display_back_btn: true as boolean,
      disable_next_btn: false as boolean
    };
  },
  watch: {
    candidate_stepper(new_value) {
      this.display_back_btn = new_value < 5;
    }
  },
  computed: {
    ...mapGetters("onboarding", {
      get_candidate_data: GET_CANDIDATE_DATA
    }),
    ...mapGetters("auth", {
      get_user: GET_USER_DETAILS
    })
  },
  methods: {
    ...mapMutations("auth", {
      update_user_state: USER_STATE
    }),
    ...mapMutations("onboarding", {
      set_candidate_data: SET_CANDIDATE_DATA,
      reset_candidate_data: RESET_CANDIDATE_DATA,
      set_onboarding_loading: SET_ONBOARDING_LOADING
    }),
    ...mapActions("onboarding", {
      nep_post_data: NEP_POST_DATA
    }),
    ...mapMutations({
      set_root_error: ROOT_ERROR
    }),
    async go_back_handle() {
      if (this.candidate_stepper === 1) this.$router.back();
      else this.candidate_stepper -= 1;
    },
    async update_stepper({ step, value }: { step: number; value: any }) {
      if (step === 1) {
        const payload: SetCandidateDataPayload = {
          obj_name: "candidate_avatar",
          value: value
        };
        this.set_candidate_data(payload);
      }
      // For candidate cv
      else if (step === 2) {
        const payload: SetCandidateDataPayload = {
          obj_name: "candidate_resume",
          value: value
        };
        this.set_candidate_data(payload);
      }
      // For candidate availability
      else if (step === 3) {
        const payload: SetCandidateDataPayload = {
          obj_name: "step3",
          value: value
        };
        this.set_candidate_data(payload);
      } else if (step === 4) {
        const payload: SetCandidateDataPayload = {
          obj_name: "step4",
          value: value
        };
        this.set_candidate_data(payload);
      }
      if (step === 4) {
        this.complete_candidate_onboarding();
      } else this.candidate_stepper += 1;
    },
    /**
     * Function to add candidate data in backend
     */
    async complete_candidate_onboarding() {
      // Call store function => for candidate onboarding
      const obj: CandidateData = this.get_candidate_data;
      let payload = NepPayload;
      payload.first_name = this.get_user.first_name;
      payload.last_name = this.get_user.last_name;
      payload.email_address = this.get_user.email;
      payload.professional_experience.profile_photo = obj.candidate_avatar[0];
      payload.professional_experience.resume = obj.candidate_resume[0];
      payload = {
        ...payload,
        ...obj.step3,
        ...obj.step4
      };
      const response = await this.nep_post_data(payload);
      // If candidate onboarding successfully => navigate to onboard success page
      if (response) {
        localStorage.setItem("user_uid", response.uid);
        this.update_user_state(UserState.ONBOARDING_COMPLETED);
        await this.$router.push({
          name: "onboarding-success"
        });
        this.set_onboarding_loading(false);
        this.reset_candidate_data(); // Reset candidate data
      }
      // If candidate onboarding failed => reset stepper
      else {
        this.candidate_stepper = 4;
        this.set_onboarding_loading(false);
      }
    }
  }
});

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"page"},[_c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',[_c('p',{staticClass:"mt-3 base-body-text"},[_vm._v(" Throughout the NEP academics, program, participants work to develop five mindsets for lifelong learning __ Wordly, Action, Reflective, Analytical and Collaborative (NEP Mindsets). The questions below guage how you currently employ these mindsets. ")])]),_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"form-field"},[_c('label',{staticClass:"base-body-text form-field-label"},[_vm._v(" Please give us example of having to adapt your point of view to find a solution to a problem. What did you learn in the process? "),_c('p',{staticClass:"base-body-text"},[_vm._v("(300 words max)")])]),_c('v-textarea',{staticClass:"form-field-input",attrs:{"rows":"2","required":"","solo":"","rules":[
              function (v) { return (v && v.split(' ').length <= 300) || 'Max 300 words'; }
            ]},model:{value:(_vm.solution_to_problem),callback:function ($$v) {_vm.solution_to_problem=$$v},expression:"solution_to_problem"}})],1)]),_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"form-field"},[_c('label',{staticClass:"base-body-text form-field-label"},[_vm._v(" Describe a time when you were part of instituting a change that was not entirely well recieved. What was the most important lesson you learned? "),_c('p',{staticClass:"base-body-text"},[_vm._v("(300 words max)")])]),_c('v-textarea',{staticClass:"form-field-input",attrs:{"rows":"2","required":"","solo":"","rules":[
              function (v) { return (v && v.split(' ').length <= 300) || 'Max 300 words'; }
            ]},model:{value:(_vm.lesson_learned),callback:function ($$v) {_vm.lesson_learned=$$v},expression:"lesson_learned"}})],1)]),_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"form-field"},[_c('label',{staticClass:"base-body-text form-field-label"},[_vm._v(" What strategies do you use to take stock of your own performance? "),_c('p',{staticClass:"base-body-text"},[_vm._v("(300 words max)")])]),_c('v-textarea',{staticClass:"form-field-input",attrs:{"rows":"2","required":"","solo":"","rules":[
              function (v) { return (v && v.split(' ').length <= 300) || 'Max 300 words'; }
            ]},model:{value:(_vm.strategies_taken_for_performance),callback:function ($$v) {_vm.strategies_taken_for_performance=$$v},expression:"strategies_taken_for_performance"}})],1)]),_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"form-field"},[_c('label',{staticClass:"base-body-text form-field-label"},[_vm._v(" Give an example of approching a complex problem inside your organization where you successfully broke down the issue into smaller tasks that your team was then able to act on. "),_c('p',{staticClass:"base-body-text"},[_vm._v("(300 words max)")])]),_c('v-textarea',{staticClass:"form-field-input",attrs:{"rows":"2","required":"","solo":"","rules":[
              function (v) { return (v && v.split(' ').length <= 300) || 'Max 300 words'; }
            ]},model:{value:(_vm.complex_problem),callback:function ($$v) {_vm.complex_problem=$$v},expression:"complex_problem"}})],1)]),_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"form-field"},[_c('label',{staticClass:"base-body-text form-field-label"},[_vm._v(" How do you ensure that colleagues ideas have been considered and valued when working with a group? "),_c('p',{staticClass:"base-body-text"},[_vm._v("(300 words max)")])]),_c('v-textarea',{staticClass:"form-field-input",attrs:{"rows":"2","required":"","solo":"","rules":[
              function (v) { return (v && v.split(' ').length <= 300) || 'Max 300 words'; }
            ]},model:{value:(_vm.colleagues_ideas),callback:function ($$v) {_vm.colleagues_ideas=$$v},expression:"colleagues_ideas"}})],1)])],1)],1),_c('div',{staticClass:"mt-2"},[(_vm.valid)?_c('v-btn',{staticClass:"base-body-text base-submit-button next-btn",attrs:{"type":"submit"},on:{"click":_vm.submit_form}},[_vm._v(" "+_vm._s(_vm.$t("candidate-onboarding.next-btn"))+" ")]):_c('v-btn',{staticClass:"base-body-text base-disable-button next-btn",attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t("candidate-onboarding.next-btn"))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
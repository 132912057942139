var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"page"},[_c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{staticClass:"mt-2"},[_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"form-field"},[_c('label',{staticClass:"base-body-text form-field-label"},[_vm._v(" Why are you intrested in joining the National Experts Program? ")]),_c('v-textarea',{staticClass:"form-field-input",attrs:{"rows":"2","rules":[function (v) { return !!v || 'Field is Required'; }],"required":"","solo":""},model:{value:(
              _vm.why_are_you_interested_in_joining_the_national_experts_program
            ),callback:function ($$v) {
              _vm.why_are_you_interested_in_joining_the_national_experts_program
            =$$v},expression:"\n              why_are_you_interested_in_joining_the_national_experts_program\n            "}})],1)]),_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"form-field"},[_c('label',{staticClass:"base-body-text form-field-label"},[_vm._v(" How many years have you worked in your sectors? ")]),_c('v-text-field',{staticClass:"form-field-input",attrs:{"type":"number","required":"","solo":"","rules":[function (v) { return !!v || 'Year is Required'; }]},model:{value:(_vm.how_many_years_have_you_worked_in_your_sector),callback:function ($$v) {_vm.how_many_years_have_you_worked_in_your_sector=$$v},expression:"how_many_years_have_you_worked_in_your_sector"}})],1)]),_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"form-field"},[_c('label',{staticClass:"base-body-text form-field-label"},[_vm._v(" Provide 3 specific examples of global trends or technological innovations that you believe will change your sector in the next five years, and why? "),_c('p',{staticClass:"base-body-text"},[_vm._v("(300 words max)")])]),_c('v-textarea',{staticClass:"form-field-input",attrs:{"rows":"2","required":"","solo":"","rules":[
              function (v) { return (v && v.split(' ').length <= 300) || 'Max 300 words'; }
            ]},model:{value:(
              _vm.provide_3_specific_examples_of_trends_that_you_believe_will_change_your_sector_in_the_next_five_years_and_why
            ),callback:function ($$v) {
              _vm.provide_3_specific_examples_of_trends_that_you_believe_will_change_your_sector_in_the_next_five_years_and_why
            =$$v},expression:"\n              provide_3_specific_examples_of_trends_that_you_believe_will_change_your_sector_in_the_next_five_years_and_why\n            "}})],1)])],1)],1),_c('div',{staticClass:"mt-2"},[(_vm.valid)?_c('v-btn',{staticClass:"base-body-text base-submit-button next-btn",attrs:{"type":"submit"},on:{"click":_vm.submit_form}},[_vm._v(" "+_vm._s(_vm.$t("candidate-onboarding.next-btn"))+" ")]):_c('v-btn',{staticClass:"base-body-text base-disable-button next-btn",attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t("candidate-onboarding.next-btn"))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
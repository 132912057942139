


































































































































import Vue from "vue";

export default Vue.extend({
  name: "NepStep3",
  data: () => ({
    valid: false,
    solution_to_problem: "",
    lesson_learned: "",
    strategies_taken_for_performance: "",
    complex_problem: "",
    colleagues_ideas: "",

    why_are_you_interested_in_joining_the_national_experts_program: ""
  }),
  methods: {
    submit_form() {
      if (this.valid) {
        const payload = {
          solution_to_problem: this.solution_to_problem,
          lesson_learned: this.lesson_learned,
          strategies_taken_for_performance:
            this.strategies_taken_for_performance,
          complex_problem: this.complex_problem,
          colleagues_ideas: this.colleagues_ideas
        };
        this.$emit("update_stepper", { step: 3, value: payload });
      }
    }
  }
});

































































































import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  GET_ONBOARDING_LOADING,
  SET_ONBOARDING_LOADING
} from "@/store/modules/onboarding/constants";
import { TranslateResult } from "vue-i18n";
import { SITE_DIRECTION, UPLOAD_FILES } from "@/store/modules/common/constants";
import { SiteDirections } from "@/store/modules/common/interfaces";

export default Vue.extend({
  name: "CandidateResume",
  data() {
    return {
      resume: null as File | null,
      drop_file_image: require("@/assets/illustrations/file.svg"),
      selected_resume: null as string | ArrayBuffer | null,
      error_msg: "" as TranslateResult,
      show_error_alert: false as boolean
    };
  },
  computed: {
    SiteDirections() {
      return SiteDirections;
    },
    ...mapGetters("common", {
      get_site_direction: SITE_DIRECTION
    }),
    ...mapGetters("onboarding", {
      get_onboarding_loading: GET_ONBOARDING_LOADING
    })
  },
  methods: {
    ...mapActions("common", {
      upload_files: UPLOAD_FILES
    }),
    ...mapMutations("onboarding", {
      set_onboarding_loading: SET_ONBOARDING_LOADING
    }),
    /**
     * Validate user selected resume through drag & drop
     * @param value{DragEvent}
     */
    process_dropped_resume(value: DragEvent) {
      if (value && value.dataTransfer) {
        this.resume = value.dataTransfer.files[0];
        if (this.validate_resume_type(this.resume)) {
          this.get_resume_url(this.resume);
        }
      }
    },
    /**
     * Validate user selected resume through file uploader
     * @param file{File}
     */
    process_selected_resume(file: File) {
      this.resume = file;
      if (this.validate_resume_type(this.resume)) {
        this.get_resume_url(this.resume);
      }
    },
    /**
     * Validate selected resume type: .png, .jpg & .svg are valid types
     * @param file{File}  => selected file
     * @return boolean
     */
    validate_resume_type(file: File): boolean {
      if (file.size > 3e7) {
        this.error_msg = this.$t("candidate-onboarding.resume-size-error");
        this.show_error_alert = true;
        this.resume = null;
        this.selected_resume = null;
        return false;
      } else if (
        file.type === "text/plain" ||
        file.type === "application/pdf" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        file.type === "application/doc" ||
        file.type === "application/ms-doc" ||
        file.type === "application/msword"
      ) {
        this.error_msg = "";
        this.show_error_alert = false;
        return true;
      } else {
        this.error_msg = this.$t("candidate-onboarding.unsupported-error");
        this.show_error_alert = true;
        this.resume = null;
        this.selected_resume = null;
        return false;
      }
    },
    /**
     * Get resume url to display
     * @param resume{File}  => selected file
     */
    get_resume_url(resume: File) {
      const reader = new FileReader();
      reader.addEventListener("load", (e: ProgressEvent) => {
        const target = e.target as FileReader;
        if (target) this.selected_resume = target.result;
        else {
          this.error_msg = this.$t("candidate-onboarding.unsupported-error");
          this.resume = null;
          this.selected_resume = null;
        }
      });
      reader.readAsDataURL(resume);
    },
    /**
     * Convert file size from bytes to MB
     * @param size{number}  file size
     * @return string file size in MB
     */
    get_file_size(size: number): string {
      return (size / (1024 * 1024)).toFixed(2);
    },
    /**
     * Reset variables
     */
    reset_resume() {
      this.resume = null;
      this.selected_resume = null;
      this.error_msg = "";
      this.show_error_alert = false;
    },
    /**
     * Submit form => pass data to parent with step and resume file
     */
    async submit_form() {
      // Convert file to Blob
      this.set_onboarding_loading(true);
      const obj = new FormData();
      const file = this.resume as File;
      obj.append("file", file);
      obj.append("path", `files/resume`);
      const files = await this.upload_files(obj);
      if (files) {
        this.$emit("update_stepper", {
          step: 2,
          value: files
        });
      }
      this.set_onboarding_loading(false);
    }
  }
});
